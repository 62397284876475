<template>
    <div class="product-list">
        <div class="top_image_banner" :style="'background-image: url(' + imageUrl + productConfigData.image + ');'">
            <span class="banner_title">{{productConfigData.pageTitle}}</span>
            <span class="banner_desc">{{productConfigData.pageSubTitle}}</span>
        </div>
        <el-dialog
            v-model="newsVisible"
            :title="newsTitle"
            width="70%"
            align-center
        >
            <div v-html="newsContent" />
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="newsVisible = false">{{productConfigData.closeBtnTitle}}</el-button>
              </span>
            </template>
        </el-dialog>
        <div v-if="'basic_data' in productAllData" class="product-view">
            <div class="category-left" id="category">
                <div class="category-view">
                    <div class="category-title">{{productConfigData.productTitle}}</div>

                        <el-collapse v-model="selectedArea" accordion style="width:95%;" @change="onCategoryTypeChanged">
                            <el-collapse-item v-for="(area, ida) in productAllData.basic_data.category" :key="ida" :title="area.categoryName" :name="area.id">
                                <el-collapse v-model="selectedCategory" accordion style="width:96%;margin-left: 10px" @change="onProductTypeChanged">
                                    <el-collapse-item v-for="(item, idx) in area.items"
                                                      :key="idx" :name="item.id" :title="item.categoryName">
                                        <div :class="'category_item ' + (selectedItem === (itm.id) ? 'category_item_selected' : '') "
                                             v-for="(itm, idd) in item.items" :key="idd"
                                             @click="onCategoryItemClicked(area.id, item.id, itm.id)"
                                        >
                                            {{itm.categoryName}}
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-collapse-item>

                        </el-collapse>


                </div>
                <div class="category-view" style="margin-top: 20px">
                    <div class="category-title">{{productConfigData.newsTitle}}</div>
                    <div class="category_item bord_title" v-for="(news, index) in newsDataList" :key="index"
                         @click="onNewsClicked(news)">{{news.title}}</div>
                </div>
                <div class="category-view" style="margin-top: 20px; margin-bottom: 40px">
                    <div class="category-title">{{productConfigData.keywordTitle}}</div>
                    <div class="keyword_row" v-if="'keyword_data' in productAllData" >
                        <div class="keyword_item" v-for="(item, idx) in productAllData.keyword_data" :key="idx"
                            @click="onSearch(item)">{{item}}</div>
                    </div>
                </div>
            </div>
            <div class="product-item-view">
                <el-row v-for="(items, idx) in showItems" :key="idx" :gutter="60" style="margin-bottom: 40px">
                    <el-col :span="6" v-for="(item, idd) in items" :key="idd" >
                        <div class="product-item" @click="onProductItemClicked(item)">
                            <div class="product-item-top">
                                <el-image
                                    style="width: 100%"
                                    :src="getFirstImage(imageUrl, item.mainImageList)"
                                    fit="contain">
                                </el-image>
                            </div>
                            <div class="product-item-bottom">
                                <span class="product-item-name">{{item.mode}}</span>
                                <span class="product-item-type">{{item.typeName}}</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import {ImageOSSUrl, DataOSSURL} from "@/js/netApi";
import LocalStorage from "@/js/localStorage";

export default {
    name: "ProductList",
    components: {

    },
    computed: {
      filterCategory() {
          return function(arrCategory, area_id) {
              if (!arrCategory) {
                  return null
              }

              let retArr = []

              for(let i = 0 ; i < arrCategory.length ; i++) {
                  let item = arrCategory[i]
                  if (!item) {
                      continue
                  }

                  if (item.upper_id === area_id) {
                      retArr.push(item)
                  }
              }

              return retArr
          }
      }
    },
    props: {
        'defaultIndex': {
            type: String,
            default: ''
        },
        'productData': {},
        'productConfig': {},
        'newsData': {}
    },
    data() {
        return {
            imageUrl: ImageOSSUrl,
            dataUrl: DataOSSURL,
            selectedArea: '1',
            selectedCategory: '1',
            selectedItem: 1,
            productAllData: {},
            showProductList: [],
            newsContent: [],
            newsTitle: '',
            newsVisible: false,
            showItems: [],
            newsDataList: [],
            productConfigData: {},
        }
    },
    watch: {
        'defaultIndex': function () {
            this.selectedArea = this.defaultIndex
            this.showCategoryByArea(this.selectedArea)
            this.selectedItem = 1

            this.onProductTypeChanged(this.selectedCategory)
        },
        'productData': function () {
            this.productAllData = this.productData
        },
        'productConfig': function () {
            this.productConfigData = this.productConfig
        },
        'newsData': function () {
            this.newsDataList = this.newsData
        },
    },
    beforeRouteEnter() {
        window.scrollTo(0,0)
    },
    mounted() {
        let subIdx = LocalStorage.getStorage('subIdx')
        //let idx = '0'
        //let itemIdx = '0'
        //let itemName = ''

        if (subIdx === '') {
            this.selectedArea = this.defaultIndex
        } else {
            this.selectedArea = subIdx
            LocalStorage.setStorage('subIdx', '')
        }
/*
        let arr = this.selectedArea.split('-')
        if (arr.length === 3) {
            idx = arr[2]
            this.selectedArea = arr[0] + '-' +  arr[1]
        } else if (arr.length === 5) {
            idx = arr[2]
            itemIdx = arr[3]
            itemName = arr[4]
            this.selectedArea = arr[0] + '-' +  arr[1]
        }*/

        //this.selectedItem = 1
        this.productAllData = this.productData
        this.productConfigData = this.productConfig

        this.newsDataList = this.newsData

        let category_id = parseInt(this.$route.query?.categoryId);
        if (isNaN(category_id) || (category_id < 0)) {
            category_id = 0
        } else {
            this.selectedArea = category_id
        }

        let series_id = parseInt(this.$route.query?.seriesId);
        if (isNaN(series_id) || (series_id < 0)) {
            series_id = 0
        } else {
            this.selectedCategory = series_id
        }

        let type_id = parseInt(this.$route.query?.typeId);
        if (isNaN(type_id) || (type_id < 0)) {
            type_id = 0
        } else {
            this.selectedItem = type_id
        }

        this.getProductItemList(category_id, series_id, type_id)

        //this.onProductTypeChanged(this.selectedCategory)
    },
    methods: {
        onBannerClicked(idx,url,type) {
            console.log(idx)
            console.log(url)
            console.log(type)
        },

        getFirstImage(prefix, imageList) {
            if (imageList.length <= 0) {
                return ""
            }

            return prefix + imageList.split(',')[0]
        },

        showCategoryByArea(selectAreaName, idx = '', itemIdx = '', itemName = '') {
            let areaId = this.getAreaIdByName(selectAreaName)
            if (!areaId) {
                return
            }

            let categoryItem = this.filterCategory(this.productAllData.basic_data.series, areaId)
            if ((!categoryItem) || (categoryItem.length <= 0)) {
                return
            }

            let i = parseInt(idx);
            if ((isNaN(i)) || (i >= categoryItem.length) || (i < 0)) {
                i = 0
            }

            let idd = parseInt(itemIdx);
            if ((isNaN(idd)) || (idd < 1)) {
                idd = 1
            }

            this.selectedCategory = categoryItem[i].name

            this.onCategoryItemClicked(categoryItem[i].id, idd, itemName)
        },

        onSearch(keyword) {
            if (keyword.length > 0) {
                this.$emit('onSearched', keyword)
            }
        },

        onNewsClicked(newsObj) {

            this.newsContent = newsObj.content
            this.newsTitle = newsObj.title
            this.newsVisible = true

        },

        formatNews(newsText) {
            return newsText.split('\r\n')
        },

        getAreaIdByName(areaName) {
            if ((!areaName) || (!this.productAllData.basic_data.area)) {
                return 1
            }

            for (let i = 0 ; i < this.productAllData.basic_data.area.length ; i++) {
                let area = this.productAllData.basic_data.area[i]
                if (!area) {
                    continue
                }

                if (area.menu_id === areaName) {
                    return area.id
                }
            }

            return 1
        },

        getCategoryIdByName(categoryName) {
            if ((!categoryName) || (!this.productAllData.basic_data.series)) {
                return 1
            }

            for (let i = 0 ; i < this.productAllData.basic_data.series.length ; i++) {
                let series = this.productAllData.basic_data.series[i]
                if (!series) {
                    continue
                }

                if (series.name === categoryName) {
                    return series.id
                }
            }

            return 1
        },

        onCategoryTypeChanged(category_id) {
            this.onCategoryItemClicked(category_id, 0, 0)
        },

        onProductTypeChanged(series_id) {
            this.onCategoryItemClicked(this.selectedArea, series_id, 0)
        },

        onCategoryItemClicked(category_id, series_id, type_id) {
            this.selectedItem = type_id
            this.getProductItemList(category_id, series_id, type_id)

            let category = document.getElementById('category')
            if (category) {
                window.scrollTo({
                    top: category.offsetTop - 80,
                    behavior: "smooth"
                })
            }

        },

        getProductItemList(category_id, series_id, type_id) {
            this.showItems = []
            let currentIdx = 0

            if ((!this.productAllData) || (!this.productAllData.product_data)) {
                return
            }

            let product_data = this.productAllData.product_data

            for (let i = 0 ; i < product_data.length ; i++) {
                let item = product_data[i]
                if (!item) {
                    continue
                }

                if ((category_id <= 0) ||
                    ((item.categoryId === category_id) &&(series_id <= 0)) ||
                    ((item.seriesId === series_id) && (type_id <= 0)) ||
                    (item.typeId === type_id))  {
                    let rowIdx = Math.floor(currentIdx / 4)
                    let colIdx = currentIdx % 4

                    if (colIdx === 0) {
                        this.showItems.push([])
                    }

                    this.showItems[rowIdx].push(item)
                    currentIdx++
                }
            }
        },

        onProductItemClicked(item) {
            let urlObj = this.$router.resolve({path: '/productItem'})

            localStorage.setItem("currentProduct", JSON.stringify(item))

            window.open(urlObj.href, "_blank")

        }
    }
}
</script>

<style scoped>
.product-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.news_content_view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 40px;
    box-sizing: border-box;
}

.news_content_row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px;
    font-size: 16px;
    color: #666;
}

.slideItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-size:cover;
    background-repeat: no-repeat;
    color: #FFF;
    font-size: 18px;
    width: 100%;
    height: 100%;
}

.product-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    margin-top: 40px;
}

.keyword_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.keyword_item {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-right: 12px;
    line-height: 36px;
}

.keyword_item:hover {
    color: #CFA88C;
    cursor: pointer;
}

.category-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
}

.category-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border: 1px solid #E7E9F3;
}

.product-item-title {
    display: flex;
    flex-direction: column;
    line-height: normal !important;
}

.product-item-subtitle {
    font-size: 12px !important;
    color: #666;
}

.category-title {
    background: #CFA88C;
    color: white;
    font-size: 20px;
    width: 100%;
    padding: 10px 0;
    text-align: center;
}

.category_item {
    font-size: 14px;
    border-top: 1px solid #E7E9F3;
    width: 95%;
    color: #818181;
    padding: 10px 0;
    margin-left: 6px;
}

.bord_title {
    font-weight: bold;
    color: #333;
}

.category_item_selected {
    color: #CFA88C;
    font-weight: bold;
}

.category_item:hover {
    color: #CFA88C;
    font-weight: bold;
    cursor: pointer;
}

.el-select .el-input__inner {
    font-size: 16px ;
}

.product-item-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 75%;
    margin-bottom: 80px;
}

.product-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
}

.banner_title {
    color: #FFF;
    font-size: 72px;
    white-space: pre-line;
    text-align: center;
}

.banner_desc {
    color: #FFF;
    font-size: 48px;
}

.product-item-top {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
}

.product-item-mid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
}

.product-tag {
    margin-right: 10px;
}

.product-item-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #333333;
    box-sizing: border-box;

}

.product-item-name {
    font-size: 16px;
    margin-bottom: 4px;
}

.product-item-type {
    font-size: 12px;
    color: #888888;
}

.product-item-top .el-image {
    transition: scale 1s;
    -webkit-transition: scale 1s;
}

.product-item-top .el-image:hover {
    scale: 1.1;
}

:deep(.el-collapse-item__header) {
    line-height: normal !important;
}

* {
    --el-collapse-header-font-size: 14px !important;

}

</style>
