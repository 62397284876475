<template>
    <el-container ref="app" class="container">
        <el-header class="header-container" height="80px">
            <HeaderComponent @onLangaugeChanged="onLangaugeChanged"
                             :activeIndex="currentSubPage" :need-scroll="needScore" :defaultLang="languageCode"
                             @onSearched="onSearched" @onMenuClicked="onMenuClicked" :webData="webData?.Header"
                             :langData="webData?.Langauge" :menuData="webData?.Menus"/>
        </el-header>
        <el-main>
            <router-view name="MainComponent" @onMenuClicked="onMenuClicked" :webData="webData?.Main" />
            <router-view name="ProductList" :defaultIndex="productIdx" :newsData="newsData" :productConfig="webData?.Product" :productData="productData" @onSearched="onSearched" />
            <router-view name="ProductItemComponent" :productConfig="webData?.ProductItem" :columnData="productData?.basic_data.column" />
            <router-view name="ServiceComponent" :defaultIndex="currentSubPage" :webData="webData?.Service" />
            <router-view name="DesignComponent" :webData="webData?.Design" />
            <router-view name="DesignFlowComponent" :webData="webData?.DesignFlow" />
            <router-view name="CaseComponent" :webData="webData?.Case" />
            <router-view name="ProjectComponent" :webData="webData?.Project" />
            <router-view name="AboutComponent" :defaultIndex="currentSubPage" :webData="webData?.About" :recuritData="recruitData" />
            <router-view name="SearchResultComponent" :keyword="searchKeyword" :productData="productData" :searchResult="webData?.SearchResult" />
        </el-main>
        <el-footer>
            <FooterComponent @onMenuClicked="onMenuClicked" :data="webData?.Footer" :menuData="webData?.Menus" />
        </el-footer>
        <el-backtop :right="100" :bottom="100" />
    </el-container>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent'
import LocalStorage from "@/js/localStorage"
import {ApiURL, getManagerParamStr, statistics} from "@/js/netApi"
import FooterComponent from "@/components/FooterComponent.vue";

export default {
    name: 'App',

    components: {
        FooterComponent,
        HeaderComponent,
    },
    props: {
        urlParams: {}
    },
    data() {
        return {
            currentPage: '1',
            currentSubPage: '1',
            searchKeyword: '',
            languageCode: 'zh',

            productIdx: '1',
            designIdx: '1',
            caseIdx: '1',
            serviceIdx: '1',
            aboutIdx: '1',

            needScore: true,

            webData: {},
            productData: {},
            newsData: {},
            recruitData: {},
            searchData: {}
        }
    },
    mounted() {


    },
    beforeMount()  {
        this.getAgentLang()
        this.$router.beforeResolve((to, from, next) => {
            // 在这里获取路由信息并进行相应操作
            let page = ""
            let source = ""
            let product_id = ""

            if ((from) && (typeof from.name !== 'undefined')) {
                source = from.name
            }

            if ((to) && (typeof to.name !== 'undefined')) {
                page = to.name
            }

            if (page === "productItem") {
                let tmpObj = JSON.parse(localStorage.getItem('currentProduct'))
                if (tmpObj) {
                    product_id = tmpObj.id
                }
            }

            statistics(page, source, product_id)

            //this.currentSubPage = '1'

            if (this.webData.Menus) {
                this.webData.Menus.forEach((item) => {
                    if (item.items.length > 0) {
                        item.items.forEach((itm) => {
                            if (itm.path === to.name) {
                                this.currentSubPage = item.id + "-" + itm.id
                            }
                        })
                    } else {
                        if (item.path === to.name) {
                            this.currentSubPage = item.id + ""
                        }
                    }
                })
            }

            next();
        });
    },
    methods: {
        onLangaugeChanged(lang) {
            if (this.languageCode === lang) {
                return
            }

            let noCacheFlag = 0
            if ((this.urlParams) && (this.urlParams.noCache)) {
                noCacheFlag = this.urlParams.noCache
            }

            LocalStorage.setStorage('lang', lang)
            this.languageCode = lang
            window.location.href = "/portal/index.html" + (noCacheFlag === '1' ? "?noCache=1" : "")
        },

        onSearched(keyword) {
            this.currentPage = '8'
            this.searchKeyword = keyword

            let url = this.$router.resolve({
                    path:'/search',
                    name: 'search',
                    query: {
                        keyword: this.searchKeyword
                    }
                })

            window.open(url.href, '_blank')



            //this.$router.push('/search')
        },

        getParam(url) {
            const urlObj = new URL("http://www.test.com" + url);
            const params = {};

            // 迭代 URLSearchParams 对象，构建 JSON 对象
            urlObj.searchParams.forEach((value, key) => {
                params[key] = value;
            });

            return params;

        },

        onMenuClicked(idx, subIdx, url, blank="") {
            if (url === "") {
                return
            }

            let params = this.getParam(url);

            console.log(idx)
            console.log(subIdx)
            console.log(url)
            console.log(params)

            this.currentPage = idx

            if (subIdx === "") {
                this.currentSubPag = ""
            } else {
                this.currentSubPage = idx + "-" + subIdx
            }

            this.needScore = true

            if (blank === '') {
                this.$router.push('/' + url)
                LocalStorage.setStorage('subIdx', this.currentSubPage)
            } else {
                LocalStorage.setStorage('subIdx', subIdx)

                let destUrl = this.$router.resolve({
                    path: '/' + url,
                    query: params
                })

                window.open(destUrl.href, blank)
            }

        },

        getAgentLang() {
            let noCacheFlag = 0
            if ((this.urlParams) && (this.urlParams.noCache)) {
                noCacheFlag = this.urlParams.noCache
            }

            let lang = LocalStorage.getStorage('lang');
            if ((!lang) || (lang === "")) {
                lang = ""
            }

            this.getWebData(lang, noCacheFlag)
            if (this.webData) {
                lang = this.webData.Langauge?.default_code;
                if (typeof lang === "undefined") {
                    lang = "zh"
                }

                LocalStorage.setStorage('lang', lang)

                this.languageCode = lang
            }

            this.getNewsData(this.languageCode, noCacheFlag)
            this.getRecruitData(this.languageCode, noCacheFlag)
            this.getProductData(this.languageCode, noCacheFlag)

        },

        getWebData(lang, cacheFlag=0) {
            let param = {
                lang: lang,
                m: "",
                noCache: cacheFlag
            }

            let paramObj = getManagerParamStr(param);
            if (!paramObj) {
                this.webData = null;
                return;
            }

            let url = ApiURL + "/v1/portal/getWebData?" + paramObj.url ;

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
            xhr.setRequestHeader('Portal-Timestamp', paramObj.timestamp);
            xhr.setRequestHeader('Portal-Sign', paramObj.sign);
            xhr.send();

            if (xhr.status === 200) {
                let data = JSON.parse(xhr.response);
                this.webData = data?.data;
            } else {
                // 处理请求失败的情况
                console.log( '请求失败：' + xhr.status);
                this.webData = null;
            }
        },

        getNewsData(lang, cacheFlag=0) {
            let param = {
                lang: lang,
                noCache: cacheFlag
            }

            let paramObj = getManagerParamStr(param);
            if (!paramObj) {
                this.newsData = null;
                return;
            }

            let url = ApiURL + "/v1/portal/getNews?" + paramObj.url ;

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
            xhr.setRequestHeader('Portal-Timestamp', paramObj.timestamp);
            xhr.setRequestHeader('Portal-Sign', paramObj.sign);
            xhr.send();

            if (xhr.status === 200) {
                let data = JSON.parse(xhr.response);
                this.newsData = data?.data;
            } else {
                // 处理请求失败的情况
                console.log( '请求失败：' + xhr.status);
            }
        },

        getRecruitData(lang, cacheFlag=0) {
            let param = {
                lang: lang,
                noCache: cacheFlag
            }

            let paramObj = getManagerParamStr(param);
            if (!paramObj) {
                this.recruitData = null;
                return;
            }

            let url = ApiURL + "/v1/portal/getRecruitDesc?" + paramObj.url ;

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
            xhr.setRequestHeader('Portal-Timestamp', paramObj.timestamp);
            xhr.setRequestHeader('Portal-Sign', paramObj.sign);
            xhr.send();

            if (xhr.status === 200) {
                let data = JSON.parse(xhr.response);
                this.recruitData = data?.data;
            } else {
                // 处理请求失败的情况
                console.log( '请求失败：' + xhr.status);
            }
        },

        getProductData(lang, cacheFlag=0) {
            let param = {
                lang: lang,
                m: "",
                noCache: cacheFlag
            }

            let paramObj = getManagerParamStr(param);
            if (!paramObj) {
                this.productData = null;
                return;
            }

            let url = ApiURL + "/v1/portal/getProductData?" + paramObj.url ;

            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
            xhr.setRequestHeader('Portal-Timestamp', paramObj.timestamp);
            xhr.setRequestHeader('Portal-Sign', paramObj.sign);
            xhr.send();

            if (xhr.status === 200) {
                let data = JSON.parse(xhr.response);
                this.productData = data?.data;
            } else {
                // 处理请求失败的情况
                console.log( '请求失败：' + xhr.status);
            }
        }
    }



}
</script>

<style>
#app {
    font-family: Microsoft YaHei,PingFang SC,Heiti SC,tahoma,arial,Hiragino Sans GB,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

:root {
    --el-color-primary: #CFA88C !important;

}

* {
    margin: 0;
    --el-color-primary: #CFA88C !important;
    --el-menu-hover-bg-color: #FFFFFF00;
    --el-menu-active-color: #CFA88C;
    --el-dialog-title-font-size: 24px !important;
}

.el-container {
    position: relative;
}

.el-header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    padding: 0 !important;
    box-sizing: unset !important;
}



.el-main {
    padding: 0 !important;
}

.el-footer {
    padding: 0 !important;
}

.el-input__wrapper {
    box-shadow: 0 0 0 0px !important;
    border-radius: 100px !important;
}

.el-link.el-link--default {
    color: #d0d0d080;
}

.mouse-cover-canvas {
    background-color: #CCCCCCCC;
    backdrop-filter: blur(5px);
}

</style>
